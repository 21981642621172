import React from "react";

const FAQ = () => {
  return (
    <div className="h-screen">
      <p className="text-5xl font-extrabold mt-28">FAQ Page</p>
      
    </div>
  );
};

export default FAQ;
